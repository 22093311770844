export const emojiCategory = [
    {
      id: "emoji",
      name: "emoji",
      icon: "",
      textPattern: "",
      type: "emoticon"
    }
  ];
  
  export const emojis = [
    { data: "😀", text: "😀", category: "emoji" },
    { data: "😁", text: "😁", category: "emoji" },
    { data: "😂", text: "😂", category: "emoji" },
    { data: "😃", text: "😃", category: "emoji" },
    { data: "😄", text: "😄", category: "emoji" },
    { data: "😅", text: "😅", category: "emoji" },
    { data: "😆", text: "😆", category: "emoji" },
    { data: "😇", text: "😇", category: "emoji" },
    { data: "😈", text: "😈", category: "emoji" },
    { data: "😉", text: "😉", category: "emoji" },
    { data: "😊", text: "😊", category: "emoji" },
    { data: "😋", text: "😋", category: "emoji" },
    { data: "😌", text: "😌", category: "emoji" },
    { data: "😍", text: "😍", category: "emoji" },
    { data: "😎", text: "😎", category: "emoji" },
    { data: "😏", text: "😏", category: "emoji" },
    { data: "😐", text: "😐", category: "emoji" },
    { data: "😑", text: "😑", category: "emoji" },
    { data: "😒", text: "😒", category: "emoji" },
    { data: "😓", text: "😓", category: "emoji" },
    { data: "😔", text: "😔", category: "emoji" },
    { data: "😕", text: "😕", category: "emoji" },
    { data: "😖", text: "😖", category: "emoji" },
    { data: "😗", text: "😗", category: "emoji" },
    { data: "😘", text: "😘", category: "emoji" },
    { data: "😙", text: "😙", category: "emoji" },
    { data: "😚", text: "😚", category: "emoji" },
    { data: "😛", text: "😛", category: "emoji" },
    { data: "😜", text: "😜", category: "emoji" },
    { data: "😝", text: "😝", category: "emoji" },
    { data: "😞", text: "😞", category: "emoji" },
    { data: "😟", text: "😟", category: "emoji" },
    { data: "😠", text: "😠", category: "emoji" },
    { data: "😡", text: "😡", category: "emoji" },
    { data: "😢", text: "😢", category: "emoji" },
    { data: "😣", text: "😣", category: "emoji" },
    { data: "😤", text: "😤", category: "emoji" },
    { data: "😥", text: "😥", category: "emoji" },
    { data: "😦", text: "😦", category: "emoji" },
    { data: "😧", text: "😧", category: "emoji" },
    { data: "👆", text: "👆", category: "emoji" },
    { data: "👇", text: "👇", category: "emoji" },
    { data: "👈", text: "👈", category: "emoji" },
    { data: "👉", text: "👉", category: "emoji" },
    { data: "👊", text: "👊", category: "emoji" },
    { data: "👋", text: "👋", category: "emoji" },
    { data: "👌", text: "👌", category: "emoji" },
    { data: "👍", text: "👍", category: "emoji" },
    { data: "👎", text: "👎", category: "emoji" },
    { data: "👏", text: "👏", category: "emoji" },
    { data: "👐", text: "👐", category: "emoji" },
    { data: "⚽", text: "⚽", category: "emoji" },
    { data: "⚾", text: "⚾", category: "emoji" },
    { data: "🎽", text: "🎽", category: "emoji" },
    { data: "🎾", text: "🎾", category: "emoji" },
    { data: "🎿", text: "🎿", category: "emoji" },
    { data: "🏀", text: "🏀", category: "emoji" },
    { data: "🏁", text: "🏁", category: "emoji" },
    { data: "🏂", text: "🏂", category: "emoji" },
    { data: "🏃", text: "🏃", category: "emoji" },
    { data: "🏄", text: "🏄", category: "emoji" },
    { data: "🏆", text: "🏆", category: "emoji" },
    { data: "🏇", text: "🏇", category: "emoji" },
    { data: "🏈", text: "🏈", category: "emoji" },
    { data: "🏉", text: "🏉", category: "emoji" },
    { data: "🏊", text: "🏊", category: "emoji" },
    { data: "🐭", text: "🐭", category: "emoji" },
    { data: "🐮", text: "🐮", category: "emoji" },
    { data: "🐯", text: "🐯", category: "emoji" },
    { data: "🐰", text: "🐰", category: "emoji" },
    { data: "🐱", text: "🐱", category: "emoji" },
    { data: "🐲", text: "🐲", category: "emoji" },
    { data: "🐳", text: "🐳", category: "emoji" },
    { data: "🐴", text: "🐴", category: "emoji" },
    { data: "🐵", text: "🐵", category: "emoji" },
    { data: "🐶", text: "🐶", category: "emoji" },
    { data: "🐷", text: "🐷", category: "emoji" },
    { data: "🐸", text: "🐸", category: "emoji" },
    { data: "🐹", text: "🐹", category: "emoji" },
    { data: "🐺", text: "🐺", category: "emoji" },
    { data: "🐻", text: "🐻", category: "emoji" },
    { data: "🐼", text: "🐼", category: "emoji" },
    { data: "🐽", text: "🐽", category: "emoji" }
  ];
  