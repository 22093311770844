<template>
  <div>
    <!-- 更新模板 -->
    <el-dialog
      :title="$t('vue_label_email_update_template')"
      :visible.sync="outerVisible"
      :before-close="close"
      :modal-append-to-body="false"
      :append-to-body="true"
      @close="closeDialog"
      width="550px"
      top="5%"
    >
      <div class="template-front-container" v-loading="loading">
        <div class="template-front-header">
          <!-- 新建模板 -->
          <el-button
            type="primary"
            style="margin-right: 5px"
            @click="newsTemplates"
            v-show="Managetemshow"
            >{{ $t("label.emailmerge.list.newtemplate") }}</el-button
          >
          <el-input v-model="searchKeyWords" @input="keyDown">
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="keyDown"
            ></el-button>
          </el-input>
        </div>

        <div class="template-front-content">
          <div
            class="template-folder-files"
            v-if="searchKeyWords == ''"
            v-loadmorefile="loadfilemore"
          >
            <div
              v-for="(item, index) in folderList"
              :key="index"
              class="template-folder-item"
            >
              <div
                @click="switchFolder(item, index)"
                :class="[
                  'template-folder-item-object',
                  {
                    'template-folder-item-object-active':
                      activeFolder === index,
                  },
                ]"
              >
                <span class="template-folder-item-name">
                  {{ item.name }}
                </span>
                <span class="template-folder-item-num">
                  ({{ item.templateCount ? item.templateCount : "0" }})
                </span>
              </div>
            </div>
          </div>
          <div
            class="template-folder-list"
            v-if="searchKeyWords == ''"
            v-loadmore="loadMore"
            v-loading="templateloading"
          >
            <div class="template-folder-item-list" id="templateItem">
              <draggable
                v-model="templateList"
                group="tasks"
                animation="300"
                @start="onstart"
                @end="onend"
                :move="onMove"
              >
                <div
                  v-for="(itemBase, indexBase) in templateList"
                  :key="indexBase"
                  @click="chooseTemplate(itemBase, indexBase)"
                  @mouseenter="mouseOver(indexBase)"
                  @mouseleave="mouseOut"
                  class="active-template-item"
                >
                  <div
                    :class="[
                      'active-template-item-left',
                      {
                        'active-template-item-left-active':
                          activeTemplate === indexBase,
                      },
                    ]"
                  >
                    {{ itemBase.name }}
                  </div>
                  <div class="active-template-item-right">
                    <div
                      class="active-template-item-right-box"
                      v-if="itemBase.purview == '1' && indexBase == fuindex"
                    >
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        style="cursor: pointer"
                        @click.stop="pen(itemBase)"
                        @mouseenter="penMouseOver"
                        @mouseleave="penMouseOut"
                      >
                        <use :href="penIcon"></use>
                      </svg>
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        style="cursor: pointer"
                        @click.stop="Deleteaaa(itemBase)"
                        @mouseenter="delMouseOver"
                        @mouseleave="delMouseOut"
                      >
                        <use :href="delIcon"></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
          <div class="templatebot" v-else>
            <!-- 全部邮件模板 vue_label_email_all_email_templates-->
            <div class="template-body">
              <ul>
                <li
                  v-for="(item, index) in templateListres"
                  :key="index"
                  :class="isSelectedIndex == index ? 'active' : ''"
                  @click="selectTemplate(index, item)"
                  @mouseenter="mouseOver(index)"
                  @mouseleave="mouseOut"
                >
                  <span>{{ item.name }}</span>
                  <div class="active-template-item-right">
                    <div
                      class="active-template-item-right-box"
                      v-if="item.purview == '1' && index == fuindex"
                    >
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        @click="pen(item)"
                        @mouseenter="penMouseOver"
                        @mouseleave="penMouseOut"
                      >
                        <use :href="penIcon"></use>
                      </svg>
                      <svg
                        class="active-template-item-right-icon"
                        aria-hidden="true"
                        @click="Deleteaaa(item)"
                        @mouseenter="delMouseOver"
                        @mouseleave="delMouseOut"
                      >
                        <use :href="delIcon"></use>
                      </svg>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="no-data" v-show="templateListres.length <= 0">
                <!-- 没有数据 -->
                {{ $t("label.productsales.nodata") }}
              </div>
            </div>
          </div>
        </div>

        <!--        底部按钮-->
        <div class="template-front-footer" v-show="!Managetemshow">
          <div class="template-front-footer-item" @click="saveAsnew">
            <svg class="template-front-footer-item-icon" aria-hidden="true">
              <use href="#icon-xinjian-grey"></use>
            </svg>
            <!-- 另存为新模板 -->
            <div class="template-front-footer-item-text">
              {{ $t("vue_label_email_new_template") }}
            </div>
          </div>
          <div class="template-front-footer-item" @click="Managetemplates">
            <svg class="template-front-footer-item-icon" aria-hidden="true">
              <use href="#icon-editor"></use>
            </svg>
            <div class="template-front-footer-item-text">
              {{ Managementemp }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="recovery">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="updateTemplate">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 另存为新模板 -->
    <el-dialog
      width="30%"
      top="10%"
      :title="$t('vue_label_email_new_template')"
      :visible.sync="innerVisible"
      append-to-body
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-dynamic"
        :rules="rules"
        label-position="left"
      >
        <!-- 模板名称 -->
        <el-form-item
          prop="templatename"
          :label="$t('label.emailobject.template.name')"
        >
          <el-input
            v-model="ruleForm.templatename"
            type="text"
            size="mini"
          ></el-input>
        </el-form-item>
        <!-- 主题 -->
        <el-form-item
          prop="theme"
          :label="$t('label.emailobject.template.subject')"
        >
          <el-input v-model="ruleForm.theme" type="text" size="mini"></el-input>
        </el-form-item>
        <!-- 选择文件夹 -->
        <el-form-item
          prop="selectfolder"
          :label="$t('vue_label_report_selectfolder')"
        >
          <!-- 请选择 -->
          <el-select
            v-model="ruleForm.selectfolder"
            :placeholder="$t('label.tabpage.pleaseSelectz')"
            size="mini"
          >
            <el-option
              v-for="item in folder"
              :key="item.name"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- 取消 -->
        <el-button @click="resetForm">{{
          $t("label.emailtocloudcc.button.back")
        }}</el-button>
        <!-- 确定 -->
        <el-button type="primary" @click="newsSave('ruleForm')">{{
          $t("label.tabpage.ok")
        }}</el-button>
      </div>
    </el-dialog>
    <deleteDialog
      ref="delete"
      :searchKeyWords="searchKeyWords"
      @reset="reset"
      @queryTemplateFolder="queryTemplateFolder"
      @keyDown="keyDown"
      @getRecentTemplate="getRecentTemplate"
    ></deleteDialog>
    <newsTemplate
      ref="newsTemplate"
      :filesId="filesId"
      :specialId="specialId"
      :showRelateId="showRelateId"
      @reset="reset"
      @queryTemplateFolder="queryTemplateFolder"
      @getTemplateSubject="getTemplateSubject"
      @getRecentTemplate="getRecentTemplate"
    ></newsTemplate>
  </div>
</template>

<script>
import deleteDialog from "./deleteDialog.vue";
import newsTemplate from "./newsTemplate.vue";
import draggable from "vuedraggable";
import * as emailTemplate from "../api.js";
import * as Email from "../api.js";
export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        el.addEventListener("scroll", (res) => {
          let height = res.target;
          let clientHeight = height.clientHeight;
          let scrollTop = height.scrollTop;
          let scrollHeight = height.scrollHeight;
          if (scrollHeight - scrollTop - clientHeight <= 10) {
            that.loadMore();
          }
        });
      },
    },
    loadmorefile: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        el.addEventListener("scroll", (res) => {
          let height = res.target;
          let clientHeight = height.clientHeight;
          let scrollTop = height.scrollTop;
          let scrollHeight = height.scrollHeight;
          if (scrollHeight - scrollTop - clientHeight <= 10) {
            that.loadfilemore();
          }
        });
      },
    },
  },
  props: {
    showRelateId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeFolder: -1,
      activeTemplate: -1,
      searchKeyWords: "",
      outerVisible: false,
      innerVisible: false,
      Managetemshow: false,
      draga: false,
      folderList: [
        {
          id: this.$i18n.t("label.email.template.recently.used"),
          name: this.$i18n.t("label.email.template.recently.used"),
        },
      ], //"最近使用"
      templateList: [],
      templateListres: [],
      oldList: [],
      sortIds: [],
      folder: [],
      onlyEmail: "",
      pageNum: 1,
      pageSize: 10,
      templateFolderId: "",
      recentlyname: "",
      //当前选中模板的下标
      isSelectedIndex: null,
      wangContent: null,
      etype: "",
      filesId: "",
      specialId: "",
      fuindex: "-1",
      Content: "",
      Managementemp: this.$i18n.t("label.emailobject.manage.template"), // "管理邮件模板"
      ruleForm: {
        templatename: "",
        selectfolder: "",
        theme: "",
      },
      rules: {
        templatename: [
          {
            required: true,
            message: this.$i18n.t("lable.Enter.template.name"),
            trigger: "blur",
          }, //"请输入模板名称"
        ],
        theme: [
          {
            required: true,
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_?enter_subject"
            ),
            trigger: "blur",
          },
        ],
        selectfolder: [
          {
            required: true,
            message: this.$i18n.t("label.email.template.please.select.folder"),
            trigger: "blur",
          }, //"请输入选择文件夹"
        ],
      },
      penIcon: "#icon-pen",
      delIcon: "#icon-delete",
      purview: "",
      loading: false,
      templateloading: false,
      pageNumfile: 1,
      totalCount: null,
      totalPage: null,
    };
  },
  components: { deleteDialog, newsTemplate, draggable },
  methods: {
    penMouseOver() {
      this.penIcon = "#icon-editPen-blue";
    },
    penMouseOut() {
      this.penIcon = "#icon-pen";
    },
    delMouseOver() {
      this.delIcon = "#icon-delete_blue";
    },
    delMouseOut() {
      this.delIcon = "#icon-delete";
    },
    updateTemplate() {
      if (this.etype == "message") {
        this.$emit("deliverTemplate", this.Content, this.wangContent);
      } else {
        this.$emit("deliverTemplate", this.Content, this.wangContent);
      }
      this.outerVisible = false;
      this.searchKeyWords = "";
      this.pageNumfile = 1;
      this.activeFolder = -1;
      this.reset();
    },
    // 滚动事件
    loadMore() {
      if (!this.outerVisible) {
        return;
      }
      // "最近使用"
      if (
        this.recentlyname !=
          this.$i18n.t("label.email.template.recently.used") &&
        this.pageNum < this.totalPage
      ) {
        this.pageNum++;
        this.queryTemplateByFolder();
      }
    },
    loadfilemore() {
      if (!this.outerVisible) {
        return;
      }
      if (this.pageNumfile < this.totalCount) {
        this.pageNumfile++;
        this.queryTemplateFolder();
      }
    },
    //选择模板
    selectTemplate(index, item) {
      this.isSelectedIndex = index;
      this.getTemplateSubject(item);
      this.saveRecentTemplate(item);
      // this.isInsertTem = true;
      // this.templateDetail = item;
    },
    // 保存邮件短信模板最近查看
    saveRecentTemplate(item) {
      let params = {
        etype: this.etype == "message" ? "SMS" : "EMAIL",
        name: item.name,
        id: item.id,
      };
      emailTemplate
        .saveRecentTemplate(params)
        .then(() => {
        })
        .catch(() => {});
    },
    showDialog(message) {
      if (message == "email") {
        this.Managementemp = this.$i18n.t("label.emailobject.manage.template"); //"管理邮件模板"
      } else {
        this.Managementemp = this.$i18n.t("label.email.template.manage.SMS"); //"管理短信模板"
      }
      this.etype = message;
      this.outerVisible = true;
      this.pageNumfile = 1;
      this.queryTemplateFolder();
      this.getRecentTemplate();
    },
    closeDialog() {
      this.folderList = [
        {
          id: this.$i18n.t("label.email.template.recently.used"),
          name: this.$i18n.t("label.email.template.recently.used"),
        },
      ];
      this.pageNumfile = 1;
    },
    switchFolder(item, index) {
      this.templateloading = true;
      // document.querySelector('.template-folder-files').scrollTop = 32*index;
      this.pageNum = 1;
      this.templateList = [];
      this.onlyEmail = item.name;
      if (this.activeFolder === index) {
        this.activeFolder = -1;
        this.activeTemplate = -1;
        this.templateList = [];
      } else {
        this.activeFolder = index;
        this.activeTemplate = -1;
        // "最近使用"
        if (item.name == this.$i18n.t("label.email.template.recently.used")) {
          this.recentlyname = this.$i18n.t(
            "label.email.template.recently.used"
          );
          this.getRecentTemplate();
        } else {
          this.recentlyname = "";
          this.templateFolderId = item.id;
          this.queryTemplateByFolder();
        }
      }
    },
    queryTemplateByFolder() {
      let params = {
        etype: this.etype == "message" ? "SMS" : "EMAIL",
        templateFolderId: this.templateFolderId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        backgroundSetting: "false",
      };
      emailTemplate
        .queryTemplateByFolder(params)
        .then((res) => {
          this.templateloading = false;
          this.totalPage = res.data.totalPage;
          this.templateList = [...this.templateList, ...res.data.templateList];
        })
        .catch(() => {
          this.templateloading = false;
        });
    },
    chooseTemplate(item, index) {
      this.getTemplateSubject(item);
      this.saveRecentTemplate(item);
      if (this.activeTemplate === index) {
        this.activeTemplate = -1;
      } else {
        this.activeTemplate = index;
      }
      // let res = await getEmailObjectmergeField(ins)
    },

    getTemplateSubject(item, sign = "") {
      this.Content = item;
      let params = {
        recordId: this.showRelateId,
        templateId: item.id,
      };
      Email.getEmailObjectGetTemplateBody(params)
        .then((res) => {
          if (res.data) {
            // if (!res.data.content) {
            //   res.data.mainbody = "";
            // }
            this.wangContent = res.data;
            if (sign == "pen") {
              this.$refs.newsTemplate.newtemlateDialog(
                item,
                this.etype,
                "edit",
                res.data
              );
            }
          }
        })
        .catch(() => {});
    },
    saveAsnew() {
      this.innerVisible = true;
      this.pageNumfile = 1;
      this.folder = [];
      this.queryTemplate();
      this.ruleForm.selectfolder = this.specialId;
    },
    //查询邮件短信模板文件夹接口
    queryTemplate() {
      let params = {
        foldertype: this.etype,
        backgroundSetting: false,
        purview: "",
        pageNum: this.pageNumfile,
        pageSize: 10,
      };
      emailTemplate
        .queryTemplateFolder(params)
        .then((res) => {
          this.totalCount = Math.ceil(res.data.totalCount / 10);
          if (this.pageNumfile < this.totalCount) {
            this.pageNumfile++;
            this.queryTemplate();
          }
          res.data.folderList.map((item) => {
            if (item.purview == "1") {
              this.folder.push(item);
            }
          });
          this.folder = [...this.folder];
        })
        .catch(() => {});
    },
    newsSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveTemplate();
        } else {
          return false;
        }
      });
      this.folder = [];
    },
    saveTemplate() {
      let params = {
        etype: this.etype == "message" ? "SMS" : "EMAIL",
        remark: "",
        encoding: "",
        title: this.ruleForm.theme,
        isText: "",
        mainbody: "",
        templateFolderId: this.ruleForm.selectfolder,
        isEnable: "",
        id: "",
        name: this.ruleForm.templatename,
        apiCode: "",
      };
      emailTemplate
        .saveTemplate(params)
        .then(() => {
          this.resetForm();
          // this.queryTemplateFolder();
          this.pageNum = 1;
          this.templateList = [];
          this.queryTemplateFolder();
          this.getRecentTemplate();
        })
        .catch(() => {});
      this.pageNumfile = 1;
      this.activeFolder = -1;
      this.folder = [];
    },
    resetForm() {
      this.innerVisible = false;
      this.$refs.ruleForm.resetFields();
    },
    // 切换管理邮件模版
    Managetemplates() {
      this.Managetemshow = true;
      this.purview = "1";
      this.folderList = [
        {
          id: this.$i18n.t("label.email.template.recently.used"),
          name: this.$i18n.t("label.email.template.recently.used"),
        },
      ];
      this.queryTemplateFolder();
      this.getRecentTemplate();
      this.reset();
    },
    reset() {
      // this.queryTemplateFolder();
      this.folderList = [
        {
          id: this.$i18n.t("label.email.template.recently.used"),
          name: this.$i18n.t("label.email.template.recently.used"),
        },
      ];
      this.folder = [];
      this.activeFolder = -1;
      this.activeTemplate = -1;
      this.templateList = [];
    },
    // 删除模板
    Deleteaaa(item) {
      this.$refs.delete.centerDialog(item.id);
    },
    pen(item) {
      this.getTemplateSubject(item, "pen");
      // this.$refs.newsTemplate.newtemlateDialog(item, this.etype, "edit");
    },
    newsTemplates() {
      this.$refs.newsTemplate.newtemlateDialog({}, this.etype, "new");
    },
    recovery() {
      if (this.Managetemshow) {
        this.purview = "";
        this.queryTemplateFolder();
        this.getRecentTemplate();
        this.outerVisible = true;
      } else {
        this.outerVisible = false;
      }
      this.Managetemshow = false;
      this.searchKeyWords = "";
      this.reset();
      this.pageNumfile = 1;
      this.activeFolder = -1;
    },
    close() {
      this.outerVisible = false;
      this.purview = "";
      this.Managetemshow = false;
      this.pageNumfile = 1;
      this.activeFolder = -1;
      this.reset();
    },
    onstart() {
      this.templateList.map((item) => {
        this.oldList.push(item.id);
      });
      this.draga = true;
    },
    onend() {
      this.draga = false;
      this.sortIds = [];
      this.templateList.map((item) => {
        this.sortIds.push(item.id);
      });
      let state = this.arrayEqual(this.oldList, this.sortIds);
      if (this.Managetemshow && !state) {
        this.sortPrivateTemplate();
      }
    },
    //move回调方法
    onMove(e) {
      if (!this.Managetemshow) {
        return false;
      } else if (
        this.Managetemshow &&
        e.draggedContext.element.createbyid == localStorage.getItem("userId") &&
        // 专用短信模板文件夹 "专用邮件模板文件夹"
        (this.onlyEmail == this.$i18n.t("label.email.template.private.mail") ||
          this.onlyEmail == this.$i18n.t("label.email.template.private.SMS"))
      ) {
        return true;
      } else {
        return false;
      }
    },
    //查询邮件短信模板文件夹接口
    queryTemplateFolder() {
      this.loading = true;
      let params = {
        foldertype: this.etype,
        backgroundSetting: false,
        purview: this.purview,
        pageNum: this.pageNumfile,
        pageSize: 10,
      };
      // this.loading = false
      emailTemplate
        .queryTemplateFolder(params)
        .then((res) => {
          this.totalCount = Math.ceil(res.data.totalCount / 10);
          this.loading = false;
          // res.data.folderList.map((item) => {
          //   if (item.purview == "1") {
          //     this.folder.push(item);
          //   }
          // });
          this.folderList[0].templateCount = res.data.recentCount;
          this.folderList = [...this.folderList, ...res.data.folderList];
          res.data.folderList.map((items) => {
            // "公用邮件模板文件夹" "公用短信模板文件夹"
            if (
              items.name == this.$i18n.t("label.email.template.public.mail") ||
              items.name == this.$i18n.t("label.email.template.public.SMS")
            ) {
              this.filesId = items.id;
            }
            // "专用短信模板文件夹""专用邮件模板文件夹"
            if (
              items.name == this.$i18n.t("label.email.template.private.mail") ||
              items.name == this.$i18n.t("label.email.template.private.SMS")
            ) {
              this.specialId = items.id;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //   获取邮件短信模板最近查看
    getRecentTemplate() {
      emailTemplate
        .getRecentTemplate({ etype: this.etype == "message" ? "SMS" : "EMAIL" })
        .then((res) => {
          this.templateloading = false;
          this.templateList = res.data;
        })
        .catch(() => {
          this.templateloading = false;
        });
    },
    // 专用模板文件夹排序接口
    sortPrivateTemplate() {
      let params = {
        templateIdSort: this.sortIds.join(","),
      };
      emailTemplate
        .sortPrivateTemplate(params)
        .then(() => {
        })
        .catch(() => {});
    },
    //比较两个数组的差异
    arrayEqual(arr1, arr2) {
      if (arr1 === arr2) return true;
      if (arr1.length != arr2.length) return false;
      for (var i = 0; i < arr1.length; ++i) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    },
    keyDown() {
      let params = {
        name: this.searchKeyWords,
        etype: this.etype == "message" ? "SMS" : "EMAIL",
      };
      if (this.searchKeyWords !== "") {
        emailTemplate
          .queryTemplateByName(params)
          .then((res) => {
            this.templateListres = res.data;
          })
          .catch(() => {});
      }
    },
    mouseOver(index) {
      this.fuindex = index;
    },
    mouseOut() {
      this.fuindex = "-1";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner {
  border-radius: 3px;
  border: 1px solid #dedcda;
}
.template-front-container {
  box-sizing: border-box;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
  height: 430px;
  .template-front-header {
    display: flex;
    margin-bottom: 16px;
  }
  .template-front-content {
    margin-bottom: 16px;
    width: 100%;
    // overflow-y: auto;
    border-radius: 3px;
    display: flex;
    border: 1px solid #dedcda;
    .template-folder-files {
      width: 40%;
      height: 330px;
      // min-height: 100px;
      // max-height: 330px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }
      .template-folder-item {
        width: 100%;
        // border-bottom: 1px solid #dedcda;
        .template-folder-item-object {
          height: 30px;
          padding: 16px 20px;
          background-color: #fff;
          display: flex;
          align-items: center;
          // &:hover {
          //       color: #006dcc;
          //     }
          .template-folder-item-icon {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            transform: rotate(-90deg);
            transition: all 0.5s;
          }
          .template-folder-item-name {
            font-size: 14px;
            font-weight: 500;
            // color: #080707;
            line-height: 21px;
            width: 80%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 15px;
          }
        }
        .template-folder-item-object:hover {
          cursor: pointer;
          //.template-folder-item-name{
          //  color: #006dcc;
          //}
        }
        .template-folder-item-object-active {
          color: #006dcc;
        }
        .template-folder-item-list {
          width: 100%;
          .active-template-item {
            padding: 16px 20px;
            background-color: #fff;
            display: flex;
            align-items: center;
            height: 30px;
            padding: 16px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #dedcda;
            .active-template-item-left {
              height: 30px;
              padding: 16px 20px;
              cursor: pointer;
              width: 80%;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 30px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              &:hover {
                color: #006dcc;
              }
            }

            .active-template-item-left-active {
              color: #006dcc;
            }
            .active-template-item-right {
              display: flex;
              align-items: center;
              .active-template-item-right-box {
                .active-template-item-right-icon {
                  width: 16px;
                  height: 16px;
                  margin-left: 10px;
                }
              }
            }
          }
          .active-template-item-active {
            background: #f3f6f9;
          }
        }
      }
      .template-folder-item:last-child {
        border-bottom: none;
      }
    }
    .template-folder-list {
      min-height: 100px;
      max-height: 328px;
      overflow-y: auto;
      border-left: 1px solid #dedcda;
      width: 60%;
      .template-folder-item-list {
        width: 100%;
        .active-template-item {
          padding: 16px 20px;
          background-color: #fff;
          border-bottom: 1px solid #dedcda;
          display: flex;
          align-items: center;
          height: 30px;
          justify-content: space-between;
          .active-template-item-left {
            height: 30px;
            cursor: pointer;
            width: 70%;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:hover {
              color: #006dcc;
            }
          }

          .active-template-item-left-active {
            color: #006dcc;
          }
          .active-template-item-right {
            display: flex;
            align-items: center;
            .active-template-item-right-box {
              .active-template-item-right-icon {
                width: 16px;
                height: 16px;
                margin-left: 10px;
              }
            }
          }
        }
        .active-template-item-active {
          background: #f3f6f9;
        }
      }
      // }
      .template-folder-item:last-child {
        border-bottom: none;
      }
    }
  }

  .template-front-footer {
    display: flex;
    .template-front-footer-item {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &-icon {
        width: 14px;
        height: 14px;
        margin-right: 10px;
      }
      &-text {
        font-size: 14px;
        font-weight: 400;
        color: #080707;
        line-height: 21px;
      }
      &-text:hover {
        cursor: pointer;
        color: #006dcc;
      }
    }
    .template-front-footer-item:first-child {
      // margin-bottom: 0;
      margin-right: 30px;
    }
  }
  .templatebot {
    width: 100%;
    // margin-top: 10px;
    // border: 1px solid #d8d8d8;
    // max-height: 348px;
    // padding: 10px 0;
    // height: 283px;
    .template-body {
      overflow: auto;
      height: 250px;
      .no-data {
        width: 100%;
        text-align: center;
      }
    }
    ul {
      .active {
        background: #f3f2f2;
      }
      li {
        span {
          margin-left: 30px;
          display: inline-block;
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #080707;
        height: 30px;
        cursor: pointer;
        .active-template-item-right {
          display: flex;
          align-items: center;
          .active-template-item-right-box {
            display: flex;
            .active-template-item-right-icon {
              width: 16px;
              height: 16px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
